const mobilePlacements = [
    {
        id: 'netboard_1',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_1',
        threshold: 100,
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_1'],
        },
        nativeCustomProperties: ['video'],
    },
    {
        id: 'netboard_2',
        sizes: [
            [320, 250],
            [1920, 1080],
            [300, 250],
            [320, 400],
            [336, 280],
            [3, 3],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_2',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['board_2', 'board_fullscreen'],
        },
        finnBlink: {
            enabled: true,
            position: 'board_2',
        },
    },
    {
        id: 'netboard_3',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_3',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_3'],
        },
        finnBlink: {
            enabled: true,
            position: 'mobile_netboard3',
        },
    },
    {
        id: 'board_4',
        sizes: [
            [320, 250],
            [1920, 1080],
            [300, 250],
            [320, 400],
            [336, 280],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_4',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['board_4'],
        },
    },
    {
        id: 'board_5',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_5',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_5'],
        },
    },
    {
        id: 'board_6',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_6',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_6'],
        },
    },
    {
        id: 'board_7',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_7',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_7'],
        },
    },
    {
        id: 'board_8',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_8',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_8'],
        },
    },
    {
        id: 'board_9',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_9',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_9'],
        },
    },
    {
        id: 'board_10',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_10',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_10'],
        },
    },
    {
        id: 'board_11',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_11',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_11'],
        },
    },
    {
        id: 'board_12',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_12',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_12'],
        },
    },
    {
        id: 'board_13',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_13',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_13'],
        },
    },
    {
        id: 'board_14',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_14',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_14'],
        },
    },
    {
        id: 'board_15',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_15',
        allowedFormats: ['banner', 'native', 'video'],
        keywords: {
            'no-sno-adformat': ['board_15'],
        },
    },
    {
        id: 'backfill_1',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_backfill_1',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['board_backfill_1'],
        },
        finnBlink: {
            enabled: true,
            position: 'hayabusa_box_2',
        },
    },
    {
        id: 'backfill_2',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_backfill_2',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['board_backfill_2'],
        },
        finnBlink: {
            enabled: true,
            position: 'hayabusa_box_3',
        },
    },
    {
        id: 'backfill_3',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_backfill_3',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['board_backfill_3'],
        },
        finnBlink: {
            enabled: true,
            position: 'hayabusa_box_5',
        },
    },
    {
        id: 'backfill_4',
        sizes: [
            [320, 250],
            [300, 250],
            [320, 400],
            [336, 280],
            [1, 1],
            [5, 250],
            [5, 400],
        ],
        invCode: 'no-vg-wph-front_board_backfill_4',
        allowedFormats: ['banner', 'native'],
        keywords: {
            'no-sno-adformat': ['board_backfill_4'],
        },
    },
    {
        id: 'integration_godt-menu',
        sizes: [[1, 1]],
        invCode: 'no-vg-wph-front_integration_godt-menu',
        allowedFormats: ['native'],
        keywords: {
            'no-sno-adformat': ['ukesmeny'],
        },
    },
    {
        id: 'integration_godt-menustrip',
        sizes: [[1, 1]],
        invCode: 'no-vg-wph-front_integration_godt-menustrip',
        allowedFormats: ['native'],
        keywords: {
            'no-sno-adformat': ['ukesmeny-strip'],
        },
    },
];

export { mobilePlacements };
