/* global apntag */

import { trackEngagement } from '../tracking/pulse';
import { isMobile, isSafari, isVGApp } from '../utils/device';
import { getTagConfig } from './helpers';

/**
 * @param {HTMLElement} advert
 */
const freezeAdvertState = (advert) => {
    const sizer = advert;
    sizer.style.minHeight = `${sizer.offsetHeight}px`;
    // Make sure Blink doesn't load on refresh, if there's an advert
    advert.dataset.refreshed = 'true';
};

/**
 * @param {HTMLElement} element
 * @returns {string}
 */
const getId = (element) => {
    return element.firstElementChild.id;
};

/**
 * @param {string} id
 */
const isTopboard = (id) => {
    return id.includes('topboard');
};

/**
 * Sets the same options as previously set on the advert.
 * @param {string} id
 */
const modifyAdvert = (id) => {
    if (isMobile() || isTopboard(id)) {
        const { initialWidth, initialHeight, adResponse, utiframeId } =
            apntag.requests.tags[id];

        const [ad = null] = adResponse?.ads ?? [];
        if (!ad?.ad_type) {
            return;
        }

        const { width: iframeWidth, height: iframeHeight } =
            document.getElementById(utiframeId)?.getBoundingClientRect() ?? {};

        if (iframeWidth && iframeHeight && ad.ad_type !== 'native') {
            apntag.setSizes(id, [[iframeWidth, iframeHeight]]);
        } else {
            apntag.setSizes(id, [[initialWidth, initialHeight]]);
        }

        let extraOptions = {};
        if (ad.ad_type === 'banner') {
            extraOptions = { native: null };
        }

        apntag.modifyTag(id, {
            allowedFormats: [ad.ad_type],
            allowSmallerSizes: false,
            ...extraOptions,
        });
    }
    apntag.setKeywords(id, { 'no-sno-tag-refresh': '1' });
};
/**
 * @param {HTMLElement} advert
 * @returns {boolean}
 */
const isXLBoard = (advert) => {
    if (!getId(advert)) {
        console.log('No ID found for advert', advert);
        return false;
    }
    const { initialWidth, initialHeight, utiframeId } =
        apntag.getTag(getId(advert)) ?? {};
    const iframe = document.getElementById(utiframeId);
    const iframeRect = iframe?.getBoundingClientRect();

    if (iframeRect?.width && iframeRect?.height) {
        return iframeRect.width === 320 && iframeRect.height === 400;
    }

    return initialWidth === 320 && initialHeight === 400;
};

/**
 * @param {HTMLElement} advert
 */
const getThreshold = (advert) => {
    const { refreshThreshold = null } = getTagConfig(getId(advert));
    if (refreshThreshold) {
        return refreshThreshold;
    }

    const isXL = isXLBoard(advert);
    if (isXL) {
        return 0.4;
    }

    return isMobile() ? 0.4 : 0.4;
};

let innerHeight = null;
const getInnerHeight = () => {
    if (innerHeight === null) {
        innerHeight = window.innerHeight;
    }
    return innerHeight;
};

/**
 * @param {HTMLElement} advert
 */
const isAdvertInscreen = (advert) => {
    const rect = advert.getBoundingClientRect();
    const viewportHeight = getInnerHeight();
    const threshold = getThreshold(advert);

    const visiblePercentage =
        Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0);
    const elementVisibleRatio = visiblePercentage / rect.height;

    const inViewport =
        elementVisibleRatio >= threshold && elementVisibleRatio <= 1;

    return inViewport;
};

/**
 * @param {Element} advert
 */
const transitionAdvert = (advert) => {
    if (!(advert instanceof HTMLElement)) {
        return () => {};
    }

    advert.classList.remove('ad-fade-in');
    advert.classList.add('ad-fade-out');

    return () => {
        advert.classList.add('ad-fade-in');
        console.log('Transitioning advert', advert);

        advert.classList.remove('ad-fade-out');
    };
};

/**
 * @param {HTMLElement} advert
 */
const refreshAdvert = (advert) => {
    modifyAdvert(getId(advert));

    advert.classList.remove('advert-loaded');
    const isWallpaper = advert.dataset.subtype === 'wallpaper';
    const revealAdvert = transitionAdvert(
        isWallpaper
            ? document.querySelector('.advertory-wallpaper-background')
            : advert,
    );
    const timeout = setTimeout(() => {
        revealAdvert();
    }, 1000);
    const onLoad = () => {
        revealAdvert();
        const tagConfig = apntag.getTag(getId(advert));
        const id = `sdrn:vg:content:bfcache-advert-refresh:${tagConfig.invCode}`;
        trackEngagement({
            overrideId: id,
            elementAction: 'View',
            elementName: tagConfig.invCode,
        });
        console.log('Advert refreshed', advert, id);
        apntag.offEvent('adLoaded', getId(advert), onLoad);
        clearTimeout(timeout);
    };

    apntag.onEvent('adLoaded', getId(advert), onLoad);
    return getId(advert);
};

const MAIN_DESKTOP_ADVERTS = [
    'topboard',
    'skyscraperleft_1',
    'skyscraperright_1',
    'wallpaper',
];

const refreshAdvertsInScreen = () => {
    const adverts = $$(
        '[data-should-refresh="true"]:not(.advertory-vg-finn-blink-wrapper__front)',
    );

    /** @type {HTMLElement[]} */
    const mainDesktopAdverts = MAIN_DESKTOP_ADVERTS.map((subtype) =>
        document.querySelector(`[data-subtype="${subtype}"]`),
    );

    for (const advert of adverts) {
        if (!advert.closest('.advertory-fullscreen-scroll-wrapper')) {
            if (MAIN_DESKTOP_ADVERTS.includes(advert.dataset.subtype)) {
                if (
                    isAdvertInscreen(advert) &&
                    advert.dataset.subtype === 'topboard'
                ) {
                    const advertsToRefresh = mainDesktopAdverts.map(
                        (advert) => {
                            const firstChild = /** @type {HTMLElement} */ (
                                advert.firstElementChild
                            );
                            advert.style.removeProperty('display');
                            firstChild.classList.remove('advert-loaded');
                            firstChild.style.removeProperty('display');
                            freezeAdvertState(advert);
                            return refreshAdvert(advert);
                        },
                    );
                    apntag.refresh(advertsToRefresh);
                    break;
                }
            }
            if (isAdvertInscreen(advert)) {
                freezeAdvertState(advert);
                console.log('Refreshing advert', advert);
                const advertToRefresh = refreshAdvert(advert);
                apntag.refresh([advertToRefresh]);
                break;
            }
        }
    }
};

export const advertRefresher = () => {
    window.addEventListener('pageshow', (event) => {
        function triggerOnScroll() {
            if (window.scrollY > 0) {
                window.removeEventListener('scroll', triggerOnScroll);
                refreshAdvertsInScreen();
            }
        }

        if (event.persisted) {
            // This is needed for bf-cache, since safari scrolls to 0 on page load then to the previous scroll position
            if (isSafari()) {
                window.addEventListener('scroll', triggerOnScroll, {
                    passive: true,
                });
            } else {
                refreshAdvertsInScreen();
            }
        }
    });

    window.addEventListener('visibilitychange', async () => {
        if (
            isVGApp() &&
            document.visibilityState === 'visible' &&
            window.location.pathname === '/'
        ) {
            refreshAdvertsInScreen();
        }
    });

    window.addEventListener('popstate', async (event) => {
        // only refresh if we are going back to the page "/"
        if (
            event.target instanceof Window &&
            event.target.location.pathname === '/'
        ) {
            refreshAdvertsInScreen();
        }
    });
};
