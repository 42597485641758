const desktopAdsToReplace = [
    ['backfill_1', 'hayabusa_box_2'],
    ['backfill_2', 'hayabusa_box_3'],
    ['netboard_2', 'hayabusa_box_4'],
    ['backfill_3', 'hayabusa_box_5'],
    ['smartboard_1', 'smartboard_1'],
];

const mobileAdsToReplace = [
    ['netboard_1', 'hayabusa_box_1'],
    ['backfill_1', 'hayabusa_box_2'],
    ['backfill_2', 'hayabusa_box_3'],
    ['netboard_2', 'hayabusa_box_4'],
    ['backfill_3', 'hayabusa_box_5'],
];

const isAdBlockEnabled = async () => {
    const elem = document.querySelector(
        '.front-container div[class^="advertory-vg"]',
    );
    try {
        // @ts-ignore
        await import(/* webpackIgnore: true */ 'https://ads.vg.no/fresk.js');

        if (window.fluepapir === true) {
            return false;
        }

        if (getComputedStyle(elem).display !== 'none') {
            return false;
        }
    } catch {
        // Do nothing here
    }

    return true;
};

const getFinnBlinkSizes = (isMobile) => {
    if (isMobile) {
        return { width: 375, height: 250 };
    }

    return { width: 580, height: 450 };
};

export {
    desktopAdsToReplace,
    mobileAdsToReplace,
    isAdBlockEnabled,
    getFinnBlinkSizes,
};
